import { useState, useEffect, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";

import Events from "../services/Events";
import LoadingIndicator from "./LoadingIndicator";
import ImageUpload from "./ImageUpload";
import TextEditor from "./TextEditor";
import JSONEditor from "./JSONEditor";
import Field from "./Field";
import AdminBar from "./AdminBar";
import Bloque from "./AdminBloque";
import MaterialIcon from "./MaterialIcon";
import InputSelect from "./InputSelect";
import FormToggle from "./FormToggle";
import { StarMeter } from "./Encuesta.tsx";
import BarChart from "./estadisticas/BarChart";
import DotMenu from "./DotMenu";

import AdminService from "../services/Admin";
import { APIAdminRequest } from "../services/API";
import Helper from "../services/Helper";
import AccordionTab from "./AccordionTab";
import { DialogContext } from "../context/DialogContext";
import L10n from "../services/Locale";
import UI from "../services/UI";

import "../../css/admin-editar-evento.css";
import { DaySelect } from "./AdminWidgetHorario";
import { SubscriptionsContext } from "../context/SubscriptionsContext";

export default function AdminEditarEvento(props) {
    const [evento, setEvento] = useState(null);
    const [negocio, setNegocio] = useState(null);
    const dialogContext = useContext(DialogContext);

    const [selectedDays, setSelectedDays] = useState([0]);
    const [timeTable, setTimeTable] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});
    const [programarEnabled, setProgramarEnabled] = useState(null);
    const [surveyData, setSurveyData] = useState(null);

    const [statTypes, setStatsTypes] = useState();
    const [kpis, setKpis] = useState();
    const [ordersPerDay, setOrdersPerDay] = useState();

    const [subscriptionTypes, setSubscriptionTypes] = useState([]);
    const [eventSubscriptionTypes, setEventSubscriptionTypes] = useState([]);
    const [newEventSubscriptionType, setNewEventSubscriptionType] = useState(null);
    const [availableSubscriptionTypes, setAvailableSubscriptionTypes] = useState([]);
    const [individualSubscription, setIndividualSubscription] = useState(false);
    const [eventSubscriptionData, setEventSubscriptionData] = useState({
        pax: 1,
        price: 0,
        stock: -1,
        combinable_visits: 0,
        visits: 1,
        page_content: {},
        renewable: 1
    });
    const [priceChanged, setPriceChanged] = useState(false);
    const [loading, setLoading] = useState(true);

    const [noSubscriptionTypeAlert, setNoSubscriptionTypeAlert] = useState(false);

    const eventoID = props.eventID || 0;
    const negocioID = props.negocioID || 0;

    const navigate = useNavigate();

    const subscriptionsContext = useContext(SubscriptionsContext);

    useEffect(() => {
        if (!negocio) return () => { };

        if (eventoID == 0) {
            setEvento({
                image_url: "",
                full_booking: 0,
                disponibilidad_multiple: 0,
                offers_upselling: 0,
                offers_preorder: 0,
                nombre: "",
                price: negocio.price,
                duration: 0,
                address: null,
                description: "",
                extras_menu: {},
                negocio_id: negocioID,
                enabled: true,
                no_calendar: 0,
                has_active_subscriptions: 0,
                interval: 1,
                interval_type: "month"
            });

            setProgramarEnabled(true);
            setTimeTable([[], [], [], [], [], [], []]);
        } else {
            APIAdminRequest("get-event", { id: eventoID, locale: L10n.GetLanguage() }).then((response) => {
                if (typeof response.data.extras_menu === "string") {
                    response.data.extras_menu = JSON.parse(response.data.extras_menu || "{}") || {};
                }

                setEvento(response.data);
                setProgramarEnabled(response.data.automatic_availability);
                setTimeTable(response.data.timetable || [[], [], [], [], [], [], []]);
            });

            APIAdminRequest("surveys-get-event-data", { event_id: eventoID }).then((response) => {
                setSurveyData(response.data);
            });

            if (eventoID > 0) {
                Promise.all([APIAdminRequest("stats-get-types"), APIAdminRequest("stats-get-evento-kpi", { evento_id: eventoID }), APIAdminRequest("stats-get-evento-orders-per-day", { evento_id: eventoID })]).then(([types, KPI, ordersPerDay, surveys]) => {
                    setStatsTypes(types.data);
                    setKpis(KPI.data);
                    setOrdersPerDay(ordersPerDay.data);
                });
            }
        }

        document.body.classList.add("bg-grey");

        return () => {
            document.body.classList.remove("bg-grey");
        };
    }, [negocio]);

    useEffect(() => {
        if (!programarEnabled) {
            const n = { ...validationErrors };
            n["programar-disponibilidad"] = false;
            setValidationErrors(n);
        }
    }, [programarEnabled]);

    useEffect(() => {
        setNewEventSubscriptionType(0);
    }, [availableSubscriptionTypes]);

    useEffect(() => {
        setAvailableSubscriptionTypes(subscriptionTypes.filter((t) => eventSubscriptionTypes.findIndex((et) => et.id == t.id) == -1 && (individualSubscription ? t.pack != 1 : t.pack == 1)));
    }, [individualSubscription, subscriptionTypes, eventSubscriptionTypes]);

    useEffect(() => {
        if (negocioID) {
            APIAdminRequest("get-negocio", { id: negocioID, locale: L10n.GetLanguage() }).then((response) => {
                setNegocio(response.data);
            });
        } else {
            navigate("/admin/negocios/" + negocioID);
        }

        if (subscriptionsContext?.subscriptionsEnabled) {
            if (eventoID) {
                Promise.all([APIAdminRequest("get-subscription-types"), APIAdminRequest("get-event-subscription-types", { id: eventoID }), APIAdminRequest("get-event-subscription-data", { id: eventoID })]).then(([typesResponse, eventTypesResponse, dataResponse]) => {
                    setSubscriptionTypes(
                        typesResponse.data.sort((a, b) => {
                            return a.name < b.name ? -1 : 1;
                        })
                    );
                    setEventSubscriptionTypes(
                        eventTypesResponse.data.sort((a, b) => {
                            return a.name < b.name ? -1 : 1;
                        })
                    );

                    if (dataResponse.data.page_content) {
                        try {
                            dataResponse.data.page_content = JSON.parse(dataResponse.data.page_content);
                        } catch (e) {
                            dataResponse.data.page_content = {};
                        }
                    }

                    setEventSubscriptionData(dataResponse.data);
                    setIndividualSubscription(dataResponse.data != false);
                    setLoading(false);
                });
            }
        } else {
            setLoading(false);
        }

        document.body.classList.add("has-header");

        return () => {
            document.body.classList.remove("has-header");
        };
    }, []);

    function renderColumnOptions(section, column, section_idx, column_idx) {
        return (
            <div className="options">
                <select defaultValue={column.type}>
                    <option value="image">{L10n.__("Imagen")}</option>
                    <option value="text">{L10n.__("Texto")}</option>
                    <option value="faq">{L10n.__("FAQ")}</option>
                </select>
                <div className="buttons">
                    <MaterialIcon
                        name="delete"
                        tooltip={section.length == 2 ? "Borrar columna" : "Borrar fila"}
                        onClick={(e) => {
                            e.preventDefault();
                            const n = { ...eventSubscriptionData };

                            if (section.length == 2) {
                                // Borrar columna
                                n.page_content.sections[section_idx].splice(column_idx, 1);
                            } else {
                                // Borrar fila
                                n.page_content.sections.splice(section_idx, 1);
                            }

                            $(".floating-tooltip").hide();
                            setEventSubscriptionData(n);
                        }}
                    />
                    {section.length == 1 && (
                        <MaterialIcon
                            name="add"
                            tooltip="Añadir columna"
                            onClick={(e) => {
                                e.preventDefault();
                                const n = { ...eventSubscriptionData };
                                n.page_content.sections[section_idx].push({ type: "text" });
                                setEventSubscriptionData(n);
                            }}
                        />
                    )}
                </div>
            </div>
        );
    }

    function alterContent(sectionIndex, columnIndex, data) {
        const n = { ...eventSubscriptionData };
        for (let key in Object.keys(data)) {
            n.page_content.sections[sectionIndex][columnIndex][key] = data[key];
        }
        setEventSubscriptionData(n);
    }

    const save = useCallback(
        (notifySaved) => {
            const doSave = (confirmationMessage) => {
                let validated = true;
                const n = { ...validationErrors };
                for (let key of ["image_url", "nombre", "description"]) {
                    if (!evento[key]) {
                        validated = false;
                        n[key] = true;
                    }
                }

                if (!evento.no_calendar) {
                    if (programarEnabled && timeTable.reduce((a, v) => a + v.length, 0) == 0) {
                        n["programar-disponibilidad"] = true;
                        validated = false;
                    } else {
                        n["programar-disponibilidad"] = false;
                    }

                    if (evento.offers_preorder) {
                        if (Object.keys(evento.extras_menu).length == 0) {
                            validated = false;
                            n["extras_menu"] = true;
                        } else {
                            Object.keys(evento.extras_menu).forEach((categoryName) => {
                                const category = evento.extras_menu[categoryName];
                                if (!categoryName?.length || !category.items?.length) {
                                    validated = false;
                                    n["extras_menu"] = true;
                                }

                                if (validated) {
                                    category.items.forEach((item) => {
                                        if (!item.description?.length || !item.image_url?.length || !item.name?.length) {
                                            validated = false;
                                            n["extras_menu"] = true;
                                        }
                                    });
                                }
                            });
                        }
                    }
                }

                let hasPacks = false;
                let hasNoPacks = false;
                for (let type of eventSubscriptionTypes) {
                    if (type.pack == 1) hasPacks = true;
                    if (type.pack != 1) hasNoPacks = true;
                    if (hasPacks && hasNoPacks) {
                        UI.ShowNotification(L10n.__("El evento no puede pertenecer a grupos de suscripción de tipo pack si pertenece a algún grupo que no lo sea."));
                        notifySaved(false);
                        return;
                    }
                }

                if (individualSubscription && hasPacks) {
                    UI.ShowNotification(L10n.__("El evento no puede pertenecer a ningún pack si permite suscripción individual."));
                    notifySaved(false);
                    return;
                }

                setValidationErrors(n);

                if (!validated) {
                    UI.ShowNotification(L10n.__("Por favor rellena todos los campos obligatorios."));
                    notifySaved(false);
                    return;
                }

                if (individualSubscription && eventSubscriptionData.visits != -1 && eventSubscriptionData.visits < evento.visit_cost) {
                    UI.ShowNotification(L10n.__("El número de visitas permitidas no puede ser inferior al consumo por visita."));
                    notifySaved(false);
                    return;
                }

                dialogContext.openDialog(confirmationMessage ?? L10n.__("¿Seguro que quieres guardar los cambios?"), L10n.__("Sí"), L10n.__("No"), (confirmed) => {
                    if (confirmed) {
                        const n = { ...evento };
                        const promises = [];

                        Object.values(n.extras_menu).forEach((category) => {
                            if (category.items)
                                category.items.forEach((item) => {
                                    if (item.image_url) {
                                        promises.push(AdminService.SaveUploadedImage(item.image_url));
                                    }
                                });
                        });

                        Promise.all(promises).then((responses) => {
                            let index = 0;
                            for (let i = 0; i < Object.values(n.extras_menu).length; i++) {
                                const category = Object.values(n.extras_menu)[i];
                                if (category.items)
                                    for (let j = 0; j < category.items.length; j++) {
                                        if (category.items[j].image_url) {
                                            if (responses[index].data) n.extras_menu[Object.keys(n.extras_menu)[i]].items[j].image_url = responses[index].data;
                                            index++;
                                        }
                                    }
                            }

                            AdminService.SaveUploadedImage(evento.image_url).then((response) => {
                                const url = response.data;
                                const n = { ...evento };
                                if (url) n.image_url = url;
                                n.automatic_availability = programarEnabled ? 1 : 0;
                                n.enabled = n.enabled ? 1 : 0;

                                if (subscriptionsContext?.subscriptionsEnabled) {
                                    if (individualSubscription) {
                                        n.event_subscription_data = eventSubscriptionData;
                                    }
                                    n.event_subscription_types = eventSubscriptionTypes.map((type) => {
                                        return { id: type.id };
                                    });
                                }

                                APIAdminRequest("save-event", n).then((response) => {
                                    if (!response.status) {
                                        UI.ShowNotification(response.error);
                                        notifySaved(false);
                                        return;
                                    }

                                    let redirect = false;
                                    if (!n.id) {
                                        n.id = response.data.id;
                                        redirect = true;
                                    }
                                    if (programarEnabled) {
                                        APIAdminRequest("save-availability-templates", { event_id: n.id, time_table: timeTable, template_id: evento.availability_template_id || null }).then(() => {
                                            notifySaved(true);
                                            if (redirect) {
                                                navigate("/admin/negocios/" + negocioID + "/" + n.id);
                                            } else {
                                                setEvento(n);
                                            }
                                        });
                                    } else {
                                        notifySaved(true);
                                        if (redirect) {
                                            navigate("/admin/negocios/" + negocioID + "/" + n.id);
                                        } else {
                                            setEvento(n);
                                        }
                                    }
                                });
                            });
                        });
                    } else {
                        notifySaved(false);
                    }
                });
            };

            if (parseFloat(evento.price) == 0 && !priceChanged) {
                doSave(L10n.__(subscriptionsContext.subscriptionsEnabled ? "El precio fuera de suscripción es de 0 €. ¿Seguro que deseas continuar?" : "El precio de este evento es de 0 €. ¿Seguro que deseas continuar?"));
            } else {
                doSave();
            }
        },
        [evento, programarEnabled, timeTable, individualSubscription, eventSubscriptionData, eventSubscriptionTypes]
    );

    if (!evento || !negocio || loading) {
        return <LoadingIndicator />;
    }

    return (
        <div className="editar-evento edit-page">
            <AdminBar onSave={save} />
            <div className="header">
                <h2>{evento.nombre ? evento.nombre : L10n.__("Nuevo evento")}{!!(negocio?.name) && " | " + negocio.name}</h2>
                {eventoID != 0 && (
                    <FormToggle
                        tooltip={L10n.__((evento.enabled ? "Desactivar" : "Activar") + " evento")}
                        large={true}
                        defaultValue={evento.enabled}
                        onChange={(value) => {
                            const n = { ...evento };
                            n.enabled = value;
                            setEvento(n);
                        }}
                    />
                )}
                {eventoID != 0 && (
                    <div style={{ marginLeft: "1em" }} className="delete-evento-container">
                        <MaterialIcon
                            onClick={(e) => {
                                dialogContext.openDialog(L10n.__("¿Seguro que quieres borrar este evento?"), L10n.__("Sí"), L10n.__("No"), (confirmed) => {
                                    if (confirmed) {
                                        APIAdminRequest("delete-event", { id: eventoID }).then(() => {
                                            navigate("/admin/negocios/" + negocioID);
                                        });
                                    }
                                });
                            }}
                            name="delete"
                            tooltip={L10n.__("Borrar evento")}
                        />
                    </div>
                )}
            </div>
            <Bloque className="columnas">
                <div className="columna">
                    <ImageUpload
                        className={validationErrors["image_url"] ? "error" : ""}
                        recommendedWidth={600}
                        recommendedHeight={300}
                        maxFileSize={600}
                        initialValue={evento.image_url}
                        onChange={(value) => {
                            const newEvento = { ...evento };
                            newEvento.image_url = value;
                            setEvento(newEvento);
                        }}
                    />
                    <div className="toggles">
                        <div>
                            Calendario
                            <div
                                data-tooltip={L10n.__("Este evento utiliza el sistema de reservas.")}
                                className={"has-tooltip form-toggle" + (!evento.no_calendar ? " active" : "")}
                                onClick={() => {
                                    const n = { ...evento };
                                    n.no_calendar = n.no_calendar ? 0 : 1;
                                    setEvento(n);
                                }}
                            />
                        </div>
                        {!evento.no_calendar && <div>
                            Full booking
                            <div
                                data-tooltip={L10n.__("Una reserva de este evento conlleva la ocupación de todas las plazas.")}
                                className={"has-tooltip form-toggle" + (evento.full_booking == 1 ? " active" : "")}
                                onClick={() => {
                                    const n = { ...evento };
                                    n.full_booking = n.full_booking == 1 ? 0 : 1;
                                    setEvento(n);
                                }}
                            />
                        </div>}
                        {!evento.no_calendar && <div>
                            {L10n.__("Disponibilidad múltiple")}
                            <div
                                data-tooltip={L10n.__("El cliente puede seleccionar más de una fecha consecutiva.")}
                                className={"has-tooltip form-toggle" + (evento.disponibilidad_multiple == 1 ? " active" : "")}
                                onClick={() => {
                                    const n = { ...evento };
                                    n.disponibilidad_multiple = n.disponibilidad_multiple == 1 ? 0 : 1;
                                    setEvento(n);
                                }}
                            />
                        </div>}
                        {!evento.no_calendar && <div>
                            Extras
                            <div
                                data-tooltip={L10n.__("Mostrar la selección de extras al seleccionar este evento.")}
                                className={"has-tooltip form-toggle" + (evento.offers_preorder == 1 ? " active" : "")}
                                onClick={() => {
                                    const n = { ...evento };
                                    n.offers_preorder = n.offers_preorder == 1 ? 0 : 1;
                                    setEvento(n);

                                    if (n.offers_preorder) {
                                        setTimeout(() => {
                                            $("html, body").animate({ scrollTop: $(".accordion-tab.extras").offset().top - 175 });
                                        });
                                    }
                                }}
                            />
                        </div>}
                        {!subscriptionsContext?.subscriptionsEnabled && (
                            <div>
                                Cross-selling
                                <div
                                    data-tooltip={L10n.__("Ofrece reservar en otros tipos de negocio complementarios al seleccionar este evento.")}
                                    className={"has-tooltip form-toggle" + (evento.offers_upselling == 1 ? " active" : "")}
                                    onClick={() => {
                                        const n = { ...evento };
                                        n.offers_upselling = n.offers_upselling == 1 ? 0 : 1;
                                        setEvento(n);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="columna">
                    <div className="columnas">
                        <Field
                            placeholder={L10n.__("Nombre")}
                            className={"expand" + (validationErrors["nombre"] ? " error" : "")}
                            type="string"
                            defaultValue={evento.nombre}
                            maxLength={25}
                            onChange={(value) => {
                                const newEvento = { ...evento };
                                newEvento.nombre = value;
                                setEvento(newEvento);
                            }}
                        />
                        <select
                            className="duracion"
                            defaultValue={0}
                            onChange={(e) => {
                                const newEvento = { ...evento };
                                newEvento.duration = e.target.value;
                                setEvento(newEvento);
                            }}>
                            <option value={0}>N/A</option>
                            <option value={15}>15 min</option>
                            <option value={30}>30 min</option>
                            <option value={45}>45 min</option>
                            <option value={60}>1 h</option>
                            <option value={75}>1 h 15 min</option>
                            <option value={90}>1 h 30 min</option>
                            <option value={105}>1 h 45 min</option>
                            <option value={120}>2 h</option>
                        </select>
                        <Field
                            tooltip={subscriptionsContext?.subscriptionsEnabled && L10n.__("Recargo por persona extra fuera de suscripción")}
                            className="precio"
                            type="currency"
                            defaultValue={evento.price > 0 ? evento.price : ""}
                            onChange={(value) => {
                                const newEvento = { ...evento };
                                if (value != newEvento.price) setPriceChanged(true);
                                newEvento.price = value;
                                setEvento(newEvento);
                            }}
                        />
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}><Field
                        placeholder={negocio.address || L10n.__("Dirección")}
                        className="expand"
                        id="campo-direccion"
                        disabled={evento.address === null}
                        type="string"
                        style={{ flex: 1 }}
                        defaultValue={evento.address}
                        onChange={(value) => {
                            const newEvento = { ...evento };
                            newEvento.address = value;
                            setEvento(newEvento);
                        }}
                    /><FormToggle defaultValue={!!evento.address} onChange={value => {
                        const newEvento = { ...evento };
                        if (value) {
                            newEvento.address = "";
                            setTimeout(() => {
                                $("#campo-direccion").focus();
                            }, 100);
                        } else newEvento.address = null;
                        setEvento(newEvento);
                    }} /></div>
                    {!evento.no_calendar && <div className={"programar-container" + (validationErrors["programar-disponibilidad"] ? " error" : "")}>
                        {programarEnabled != null && (
                            <FormToggle
                                className="programar-enabled"
                                defaultValue={programarEnabled}
                                onChange={(value) => {
                                    setProgramarEnabled(value);
                                }}
                            />
                        )}
                        <h2 className="programar-disponibilidad">{L10n.__("Programar disponibilidad")}</h2>
                        {programarEnabled === false && (
                            <div className="programar-disabled-notice">
                                <div>{L10n.__("Deberás dar de alta las fechas manualmente en el apartado Disponibilidad")}</div>
                            </div>
                        )}
                        <DaySelect className={programarEnabled ? "" : "disabled"} onChange={(selectedDays) => setSelectedDays(selectedDays)} />
                        <div className={(programarEnabled ? "" : "disabled ") + "timetable-editor"}>
                            <div className="heading">
                                <div>{L10n.__("Plazas")}</div>
                                <div>{L10n.__("Hora")}</div>
                            </div>
                            {timeTable !== null &&
                                timeTable[selectedDays[0]].map((slot, slot_index) => {
                                    let equal = true;
                                    for (let i = 1; i < selectedDays.length; ++i) {
                                        if (timeTable[selectedDays[i]].length != timeTable[selectedDays[0]].length) {
                                            equal = false;
                                            break;
                                        }

                                        for (let j = 0; j < timeTable[selectedDays[0]].length; j++) {
                                            if (timeTable[selectedDays[i]][j].pax != timeTable[selectedDays[0]][j].pax || timeTable[selectedDays[i]][j].time != timeTable[selectedDays[0]][j].time) {
                                                equal = false;
                                                break;
                                            }
                                        }
                                    }
                                    if (!equal) return null;

                                    return (
                                        <div className="slot" key={slot._key}>
                                            <Field
                                                type="number"
                                                min={1}
                                                defaultValue={slot.pax}
                                                onChange={(value) => {
                                                    const modelDaySlots = timeTable[selectedDays[0]];
                                                    const n = [...timeTable];
                                                    for (let i = 0; i < selectedDays.length; ++i) {
                                                        n[selectedDays[i]] = [...modelDaySlots];
                                                        n[selectedDays[i]][slot_index].pax = value;
                                                    }
                                                    setTimeTable(n);
                                                }}
                                            />
                                            <InputSelect
                                                comboMode={true}
                                                options={Helper.GenTimeOptions()}
                                                defaultValue={slot.time}
                                                onChange={(value) => {
                                                    const modelDaySlots = timeTable[selectedDays[0]];
                                                    const n = [...timeTable];
                                                    for (let i = 0; i < selectedDays.length; ++i) {
                                                        n[selectedDays[i]] = [...modelDaySlots];
                                                        n[selectedDays[i]][slot_index].time = value;
                                                    }
                                                    setTimeTable(n);
                                                }}
                                            />
                                            <div
                                                className="delete-row"
                                                onClick={(e) => {
                                                    const n = [...timeTable];
                                                    for (let i = 0; i < selectedDays.length; ++i) {
                                                        n[selectedDays[i]].splice(slot_index, 1);
                                                    }
                                                    setTimeTable(n);
                                                }}>
                                                &times;
                                            </div>
                                        </div>
                                    );
                                })}
                            <div className="buttons">
                                <div
                                    className="plus"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        let equal = true;
                                        for (let i = 1; i < selectedDays.length; ++i) {
                                            if (timeTable[selectedDays[i]].length != timeTable[selectedDays[0]].length) {
                                                equal = false;
                                                break;
                                            }

                                            for (let j = 0; j < timeTable[selectedDays[0]].length; j++) {
                                                if (timeTable[selectedDays[i]][j].pax != timeTable[selectedDays[0]][j].pax || timeTable[selectedDays[i]][j].time != timeTable[selectedDays[0]][j].time) {
                                                    equal = false;
                                                    break;
                                                }
                                            }
                                        }

                                        const n = [...timeTable];
                                        for (let i = 0; i < selectedDays.length; ++i) {
                                            if (!equal) n[selectedDays[i]] = [];
                                            n[selectedDays[i]].push({ pax: 1, time: "00:00", _key: Math.random() });
                                        }
                                        setTimeTable(n);
                                    }}>
                                    <img src="/static/icons/plus.svg" />
                                </div>
                            </div>
                        </div>
                    </div>}
                    {subscriptionsContext?.subscriptionsEnabled && (
                        <div className="subscription-data-container">
                            <div className="subscription-type-selector">
                                <h2>{L10n.__("Suscripción")}</h2>
                                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 10 }}>
                                    {L10n.__("Permitir suscripción individual")} <FormToggle disabled={evento.has_active_subscriptions} tooltip={L10n.__(evento.has_active_subscriptions ? "Hay suscripciones activas" : "Permitir que los clientes se suscriban a este evento individualmente")} defaultValue={individualSubscription} onChange={(value) => setIndividualSubscription(value)} />
                                </div>
                                {individualSubscription && (
                                    <div className="inner-event-form">
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <label>{L10n.__("Stock")}</label>
                                            <FormToggle
                                                defaultValue={eventSubscriptionData.stock >= 0}
                                                onChange={(value) => {
                                                    const d = { ...eventSubscriptionData };
                                                    d.stock = value ? 1 : -1;
                                                    setEventSubscriptionData(d);
                                                }}
                                            />
                                        </div>
                                        <Field
                                            min={1}
                                            type="number"
                                            disabled={eventSubscriptionData.stock < 0}
                                            defaultValue={eventSubscriptionData.stock >= 0 ? eventSubscriptionData.stock : ""}
                                            onChange={(value) => {
                                                const d = { ...eventSubscriptionData };
                                                d.stock = value == "" ? -1 : parseInt(value);
                                                setEventSubscriptionData(d);
                                            }}
                                        />



                                        <div>
                                            <label>{L10n.__("Renovable")}</label>
                                            <FormToggle defaultValue={eventSubscriptionData ? eventSubscriptionData.renewable : 1} onChange={(value) => setEventSubscriptionData({ ...eventSubscriptionData, renewable: value ? 1 : 0 })} />
                                        </div>
                                        <div />

                                        {<><div style={{ display: "flex", alignItems: "center" }}>
                                            <label>{evento.no_calendar ? L10n.__("Máximo pax/suscripción") : L10n.__("Máximo pax/reserva")}</label>
                                            <FormToggle
                                                defaultValue={eventSubscriptionData.pax >= 0}
                                                onChange={(value) => {
                                                    const d = { ...eventSubscriptionData };
                                                    d.pax = value ? 1 : -1;
                                                    setEventSubscriptionData(d);
                                                }}
                                            />
                                        </div>
                                            <Field
                                                disabled={eventSubscriptionData.pax  < 0}
                                                min={1}
                                                type="number"
                                                defaultValue={eventSubscriptionData.pax || ""}
                                                onChange={(value) => {
                                                    const d = { ...eventSubscriptionData };
                                                    d.pax = value == "" ? -1 : value;
                                                    setEventSubscriptionData(d);
                                                }}
                                            /></>}

                                        {!evento.no_calendar && <div>
                                            <label>{L10n.__("Visitas combinables")}</label>
                                            <FormToggle defaultValue={eventSubscriptionData.combinable_visits} onChange={(value) => setEventSubscriptionData({ ...eventSubscriptionData, combinable_visits: value ? 1 : 0 })} />
                                        </div>}
                                        <div />

                                        {!evento.no_calendar && <div>
                                            <label>{L10n.__("Permitir exceso de plazas")}</label>
                                            <FormToggle defaultValue={eventSubscriptionData.allow_extra_pax} onChange={(value) => setEventSubscriptionData({ ...eventSubscriptionData, allow_extra_pax: value ? 1 : 0 })} />
                                        </div>}
                                        <div />

                                        <label>{L10n.__("Coste por período")}</label>
                                        <div style={{ textAlign: "right" }}>
                                            <Field
                                                type="currency"
                                                defaultValue={eventSubscriptionData.price || 0}
                                                onChange={(value) => {
                                                    const d = { ...eventSubscriptionData };
                                                    d.price = value;
                                                    setEventSubscriptionData(d);
                                                }}
                                            />
                                        </div>
                                        {<><label>{L10n.__("Período")}</label>
                                            <select
                                                defaultValue={eventSubscriptionData.interval_type + ":" + eventSubscriptionData.interval}
                                                onChange={(e) => {
                                                    const d = { ...eventSubscriptionData };
                                                    d.interval_type = e.target.value.split(":")[0];
                                                    d.interval = e.target.value.split(":")[1];
                                                    setEventSubscriptionData(d);
                                                }}>
                                                <option value={"month:1"}>{L10n.__("Mensual")}</option>
                                                <option value={"month:12"}>{L10n.__("Anual")}</option>
                                                <option value={"month:6"}>{L10n.__("Semestral")}</option>
                                                <option value={"month:3"}>{L10n.__("Trimestral")}</option>
                                                <option value={"day:1"}>{L10n.__("Diario")}</option>
                                            </select></>}

                                        {<><div style={{ display: "flex", alignItems: "center" }}>
                                            <label>{L10n.__("Visitas por período")}</label>
                                            <FormToggle
                                                defaultValue={eventSubscriptionData.visits >= 0}
                                                onChange={(value) => {
                                                    const d = { ...eventSubscriptionData };
                                                    d.visits = value ? 1 : -1;
                                                    setEventSubscriptionData(d);
                                                }}
                                            />
                                        </div>
                                            <Field
                                                min={1}
                                                type="number"
                                                disabled={eventSubscriptionData.visits < 0}
                                                defaultValue={eventSubscriptionData.visits >= 0 ? eventSubscriptionData.visits : ""}
                                                onChange={(value) => {
                                                    const d = { ...eventSubscriptionData };
                                                    d.visits = value == "" ? -1 : value;
                                                    setEventSubscriptionData(d);
                                                }}
                                            /></>}
                                    </div>
                                )}
                                <label className="secondary">{L10n.__("Crédito de visitas")}</label>

                                <div className="inner-event-form">
                                    <label>{L10n.__("Consumo por visita")}</label>
                                    <Field
                                        min={1}
                                        type="number"
                                        defaultValue={evento.visit_cost >= 1 ? evento.visit_cost : 1}
                                        onChange={(value) => {
                                            const d = { ...evento };
                                            d.visit_cost = value;
                                            setEvento(d);
                                        }}
                                    />
                                </div>
                            </div>
                            <label className="secondary">{L10n.__("Grupos de suscripción")}</label>
                            <div className="group-list">
                                {eventSubscriptionTypes.length == 0 && L10n.__("No hay ningún grupo asignado.")}
                                {eventSubscriptionTypes.map((type) => {
                                    return (
                                        <div className="type-row" key={"event-subscription-type-" + type.id}>
                                            <div style={{ opacity: (type.pack == 1 && individualSubscription) || (!type.pack && !individualSubscription) ? 0.5 : 1 }}>
                                                {type.name}
                                                {type.pack == 1 && <span className="pack-tag">{L10n.__("pack")}</span>}
                                            </div>
                                            <MaterialIcon
                                                tooltip="Eliminar"
                                                name="remove"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    const types = [...eventSubscriptionTypes];
                                                    types.splice(
                                                        types.findIndex((t) => t.id == type.id),
                                                        1
                                                    );
                                                    setEventSubscriptionTypes(types);
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="add-new-container">
                                <select
                                    className={"subscription-type" + (noSubscriptionTypeAlert ? " error" : "")}
                                    onChange={(e) => {
                                        setNoSubscriptionTypeAlert(false);
                                        setNewEventSubscriptionType(e.target.value);
                                    }}>
                                    <option value={0}></option>
                                    {availableSubscriptionTypes
                                        .sort((a, b) => {
                                            return a.name < b.name ? -1 : 1;
                                        })
                                        .map((type, idx) => {
                                            return (
                                                <option key={"new-subscription-type-" + type.id} value={type.id}>
                                                    {type.name}
                                                    {type.pack == 1 && " (" + L10n.__("pack") + ")"}
                                                </option>
                                            );
                                        })}
                                </select>
                                <MaterialIcon
                                    name="add"
                                    tooltip="Añadir"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (newEventSubscriptionType == 0) {
                                            setNoSubscriptionTypeAlert(true);
                                            return;
                                        }

                                        const types = [...eventSubscriptionTypes];
                                        types.push(availableSubscriptionTypes.find((t) => t.id == newEventSubscriptionType));
                                        setEventSubscriptionTypes(types);
                                        setNewEventSubscriptionType(availableSubscriptionTypes[0].id);
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </Bloque>
            <Bloque className="columnas" columnCount={1}>
                <div className="columna">
                    <Field
                        placeholder={L10n.__("Descripción del evento.")}
                        className={"descripcion expand" + (validationErrors["description"] ? " error" : "")}
                        type="text"
                        maxLength={500}
                        defaultValue={evento.description}
                        onChange={(value) => {
                            const newEvento = { ...evento };
                            newEvento.description = value;
                            setEvento(newEvento);

                            const n = { ...validationErrors };
                            n["description"] = false;
                            setValidationErrors(n);
                        }}
                    />
                    {!evento.no_calendar && evento.offers_preorder != 0 && (
                        <AccordionTab heading="Extras" className={"extras" + (validationErrors["extras_menu"] ? " error" : "")} defaultOpen={true}>
                            <JSONEditor
                                initialValue={evento.extras_menu || negocio.preorder_menu}
                                onChange={(data) => {
                                    const newEvento = { ...evento };
                                    newEvento.extras_menu = data;
                                    setEvento(newEvento);
                                }}
                                addEnabledToggle={true}
                                addEnabledGroupToggle={true}
                                associative={true}
                                keyTitle="Categoría"
                                properties={[{ title: L10n.__("Descripción"), optional: true, key: "description", type: "string" }]}
                                listTitle={L10n.__("Productos")}
                                listProperties={[
                                    { title: L10n.__("Nombre"), key: "name", type: "string", halfColumn: true },
                                    { title: L10n.__("Precio"), key: "price", type: "currency" },
                                    { title: L10n.__("Stock"), key: "stock", type: "number", toggleable: true, minimumValue: 1 },
                                    { title: L10n.__("Descripción"), key: "description", type: "text", maxLength: 250 },
                                    { title: L10n.__("Imagen"), key: "image_url", type: "image", recommendedWidth: 720, recommendedHeight: 360, maxFileSize: 600 * 1024 }
                                ]}
                            />
                        </AccordionTab>
                    )}
                </div>
            </Bloque>
            {subscriptionsContext?.subscriptionsEnabled && eventSubscriptionData.page_content?.sections !== null && (
                <Bloque className={"columnas page-content-editor" + (eventSubscriptionData.page_content?.sections?.length ? "" : " empty")} columnCount={1}>
                    <h2 style={{ position: "absolute", left: 25, top: 25 }}>{L10n.__("Información sobre la suscripción")}</h2>
                    {eventSubscriptionData.page_content?.sections && (
                        <div className="columna sections">
                            {eventSubscriptionData.page_content?.sections?.map((section, idx) => {
                                return (
                                    <div className="columns">
                                        {section.map((column, column_idx) => {
                                            switch (column.type) {
                                                default:
                                                    return null;
                                                case "image":
                                                    return (
                                                        <div className="section" key={"page-section-" + idx}>
                                                            {renderColumnOptions(section, column, idx, column_idx)}
                                                            <ImageUpload onChange={(value) => alterContent(idx, column_idx, { image_url: value })} defaultValue={column.image_url} />
                                                        </div>
                                                    );
                                                case "text":
                                                    return (
                                                        <div className="section" key={"page-section-" + idx}>
                                                            {renderColumnOptions(section, column, idx, column_idx)}
                                                            <label>{L10n.__("Supertítulo")}</label>
                                                            <input value={column.supertitle || ""} onChange={(e) => alterContent(idx, column_idx, { supertitle: e.target.value })} />
                                                            <label>{L10n.__("Título")}</label>
                                                            <input value={column.title || ""} onChange={(e) => alterContent(idx, column_idx, { title: e.target.value })} />
                                                            <label>{L10n.__("Contenido")}</label>
                                                            <textarea value={column.content || ""} onChange={(e) => alterContent(idx, column_idx, { content: e.target.value })} />
                                                            <div style={{ position: "relative " }}>
                                                                <label>{L10n.__("Iconos")}</label>
                                                                <div className="inner-actions">
                                                                    <MaterialIcon
                                                                        name="add"
                                                                        tooltip="Añadir icono"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            const newIcons = [...(column.icons || [])];
                                                                            newIcons.push("");
                                                                            alterContent(idx, column_idx, { icons: newIcons });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="icons-container">
                                                                {!column.icons?.length && <span>{L10n.__("No hay ningún icono")}</span>}
                                                                {column.icons?.map((url, icon_idx) => {
                                                                    return (
                                                                        <div>
                                                                            <a
                                                                                href="#"
                                                                                className="delete-icon"
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    const newIcons = [...column.icons];
                                                                                    newIcons.splice(icon_idx, 1);
                                                                                    alterContent(idx, column_idx, { icons: newIcons });
                                                                                }}>
                                                                                &times;
                                                                            </a>
                                                                            <ImageUpload
                                                                                width={64}
                                                                                height={64}
                                                                                value={url}
                                                                                onChange={(value) => {
                                                                                    const newIcons = [...column.icons];
                                                                                    newIcons[icon_idx] = value;
                                                                                    alterContent(idx, column_idx, { icons: newIcons });
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                            <label className="show-cta">
                                                                <FormToggle defaultValue={column.showButton} onChange={(value) => alterContent(idx, column_idx, { showButton: value })} /> Mostrar CTA
                                                            </label>
                                                        </div>
                                                    );
                                                case "faq":
                                                    return (
                                                        <div className="section" key={"page-section-" + idx}>
                                                            {renderColumnOptions(section, column, idx, column_idx)}
                                                            <label>{L10n.__("Título")}</label>
                                                            <input value={column.title || ""} onChange={(e) => alterContent(idx, column_idx, { title: value })} />
                                                            <br />
                                                            <div style={{ position: "relative " }}>
                                                                <label>{L10n.__("Preguntas y respuestas")}</label>
                                                                <div className="inner-actions">
                                                                    <MaterialIcon
                                                                        name="add"
                                                                        tooltip="Añadir pregunta"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            const newFaq = [...column.faq];
                                                                            newFaq.push({ question: "", answer: "" });
                                                                            alterContent(idx, column_idx, { faq: newFaq });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="section-faq">
                                                                {!column.faq?.length && <span>{L10n.__("No hay ninguna pregunta")}</span>}
                                                                {column.faq.map((item, item_idx) => {
                                                                    return (
                                                                        <div className="faq-item" key={"faq-item-" + Helper.Hash(item.question)}>
                                                                            <a
                                                                                href="#"
                                                                                className="delete-faq"
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    const newFaq = [...column.faq];
                                                                                    newFaq.splice(item_idx, 1);
                                                                                    alterContent(idx, column_idx, { faq: newFaq });
                                                                                }}>
                                                                                &times;
                                                                            </a>
                                                                            <div className="question">
                                                                                <input
                                                                                    placeholder="Pregunta"
                                                                                    value={item.question}
                                                                                    onChange={(e) => {
                                                                                        const newFaq = [...column.faq];
                                                                                        newFaq[item_idx].question = e.target.value;
                                                                                        alterContent(idx, column_idx, { faq: newFaq });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className="answer">
                                                                                <textarea
                                                                                    placeholder="Respuesta"
                                                                                    value={item.answer}
                                                                                    onChange={(e) => {
                                                                                        const newFaq = [...column.faq];
                                                                                        newFaq[item_idx].answer = e.target.value;
                                                                                        alterContent(idx, column_idx, { faq: newFaq });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    );
                                            }
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    <a
                        href="#"
                        className="new-section"
                        onClick={(e) => {
                            e.preventDefault();
                            const n = { ...eventSubscriptionData };
                            if (!n.page_content?.sections) n.page_content = { sections: [] };
                            n?.page_content?.sections.push([{ type: "text" }]);
                            setEventSubscriptionData(n);
                        }}>
                        <MaterialIcon name="add" />
                    </a>
                </Bloque>
            )}
            {eventoID > 0 && statTypes && kpis && ordersPerDay && (
                <Bloque className="columnas estadisticas" columnCount={2} style={{ paddingTop: 75, position: "relative" }}>
                    <h2 style={{ position: "absolute", left: 25, top: 25 }}>{L10n.__("Estadísticas")}</h2>
                    <div className="columna" style={{ height: 420 }}>
                        <div>
                            <h3>{L10n.__("Reservas por día de la semana")}</h3>
                            <BarChart singleMode={true} ratios={ordersPerDay} />
                        </div>
                    </div>
                    <div className="columna">
                        <h3>{L10n.__("Indicadores de rendimiento")}</h3>
                        {subscriptionsContext?.subscriptionsEnabled ? <div className="kpi-container">
                            <div className="item">
                                <div className="value">{kpis.totalActiveSubscriptions}</div>
                                <div className="caption">{L10n.__("Suscripciones activas")}</div>
                            </div>
                            <div className="item">
                                <div className="value">
                                    {kpis.totalVisits.visits} / {kpis.totalVisits.totalVisits}
                                </div>
                                <div className="caption">{L10n.__("Visitas")}</div>
                            </div>
                            <div className="item">
                                <div className="value">{(Math.floor(kpis.ordersWithExtrasRatio * 10000) / 100).toLocaleString("es")} %</div>
                                <div className="caption">{L10n.__("Reservas con extras")}</div>
                            </div>
                            <div className="item">
                                <div className="value">{(Math.floor(kpis.ordersOverPaxRatio * 10000) / 100).toLocaleString("es")} %</div>
                                <div className="caption">{L10n.__("Reservas con más personas")}</div>
                            </div>
                        </div>
                            : <div className="kpi-container">
                                <div className="item">
                                    <div className="value">{Helper.FormatAmount(Math.floor(kpis.averageAmountPerUser / 100))}</div>
                                    <div className="caption">{L10n.__("Tícket medio por persona")}</div>
                                </div>
                                <div className="item">
                                    <div className="value">{Math.round(((kpis.recurrencia * 100 + Number.EPSILON) * 100) / 100)}%</div>
                                    <div className="caption">{L10n.__("Recurrencia")}</div>
                                </div>
                                <div className="item">
                                    <div className="value">{Math.round(((kpis.upsellingRatio * 100 + Number.EPSILON) * 100) / 100)}%</div>
                                    <div className="caption">{L10n.__("Conversión upselling")}</div>
                                </div>
                                <div className="item">
                                    <div className="value">{Math.round(((kpis.crossSellingRatio * 100 + Number.EPSILON) * 100) / 100)}%</div>
                                    <div className="caption">{L10n.__("Conversión cross-selling")}</div>
                                </div>
                            </div>}
                    </div>
                </Bloque>
            )}
            {eventoID > 0 && surveyData && surveyData.map && (
                <Bloque className="encuestas columnas" columnCount={1} style={{ paddingTop: 75, position: "relative" }}>
                    <h2 style={{ position: "absolute", left: 25, top: 25 }}>{L10n.__("Valoración del evento")}</h2>
                    <div className="columna">
                        {surveyData.map((item, index) => {
                            return (
                                <div className="category" key={"survey-data-category" + index}>
                                    <h3>{item.text}</h3>
                                    <StarMeter defaultValue={item.value} />
                                </div>
                            );
                        })}
                    </div>
                </Bloque>
            )}
        </div>
    );
}
