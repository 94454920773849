import { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import AdminBar from "./AdminBar";
import { APIAdminRequest } from "../services/API";
import Field from "./Field";
import MaterialIcon from "./MaterialIcon";
import Bloque from "./AdminBloque";
import { DialogContext } from "../context/DialogContext";
import { PermissionsContext } from "../context/PermissionsContext";
import L10n from "../services/Locale";
import FormToggle from "./FormToggle";
import Modal from "./Modal";
import Helper from "../services/Helper";
import { StarMeter } from "./Encuesta";
import DotMenu from "./DotMenu";
import { SubscriptionsContext } from "../context/SubscriptionsContext";
import React from "react";

export default function AdminVendedoresVivienda(props) {
	const [vendedorTypes, setVendedorTypes] = useState([]);
	const [vendedores, setVendedores] = useState([]);
    const modalEncuesta = useRef();
    const [ modalEncuestaContents, setModalEncuestaContents ] = useState([]);
    const [ editedEncuestaTypeIndex, setEditedEncuestaTypeIndex ] = useState(-1);
	const dialogContext = useContext(DialogContext);
	const permissionsContext = useContext(PermissionsContext);
    const [ draggedVendedor, setDraggedVendedor ] = useState(null);
    const [ dragStarted, setDragStarted ] = useState(false);
	const [ sortBy, setSortBy ] = useState([]);

	const navigate = useNavigate();

	function changeVendedorType(idx, type) {
		const newTypes = [...vendedorTypes];
		newTypes[idx] = type;
		newTypes.sort((a, b) => a.order - b.order);
		setVendedorTypes(newTypes);
	}

	useEffect(() => {
		sortBy.forEach((x, idx) => {
			if (x?.by) {
				const n = [...vendedores];
				vendedores?.filter(e => e.vendedor_type_id == vendedorTypes[idx].id).sort((a, b) => {
					if (x.order == "asc") {
						if (x.by == "rating") {
							if (a.mean_rating > b.mean_rating) return 1;
							if (a.mean_rating < b.mean_rating) return -1;
							return 0;
						}
						
						if (x.by == "name") {
							return a.name.localeCompare(b.name);
						}
					} else {
						if (x.by == "rating") {
							if (a.mean_rating > b.mean_rating) return -1;
							if (a.mean_rating < b.mean_rating) return 1;
							return 0;
						}
						if (x.by == "name") {
							return b.name.localeCompare(a.name);
						}
					}
				}).forEach((e, idx) => {
					n[n.findIndex(e2 => e2.id == e.id)].order = idx;
				});
				setVendedores(n);
			}
		});
	}, [ sortBy ]);

	function getVendedores() {
		APIAdminRequest("get-vendedores", { partner_id: permissionsContext.user.id }).then(response => {
            for (let i=0; i<response.data?.length; ++i) {
                if (response.data[i].iconos) response.data[i].iconos = JSON.parse(response.data[i].iconos) || [];;
                if (response.data[i].iconos_detalle) response.data[i].iconos_detalle = JSON.parse(response.data[i].iconos_detalle) || [];
                if (response.data[i].info) response.data[i].info = JSON.parse(response.data[i].info) || {};
                if (response.data[i].encuesta) response.data[i].encuesta = JSON.parse(response.data[i].encuesta) || [];
            }
			response.data.forEach((_, idx) => {
				response.data[idx].order = idx;
			});
            setVendedores(response.data);
        });
	}

	useEffect(() => {
		getVendedores();
	}, [ vendedorTypes]);

	useEffect(() => {
		APIAdminRequest("get-vendedor-types").then((response) => {
			setVendedorTypes(response.data);
		});

        getVendedores();

		document.body.classList.add("has-header");
		document.body.classList.add("bg-grey");

        function onMouseUp() {
            setTimeout(() => {
                setDragStarted(false);
                setDraggedVendedor(null);
            }, 150);
        }

        document.addEventListener("mouseup", onMouseUp);

		return () => {
			document.body.classList.remove("bg-grey");
			document.body.classList.remove("has-header");
            document.removeEventListener("mouseup", onMouseUp);
		};
	}, []);

	const save = useCallback(notifySaved => {
		dialogContext.openDialog(L10n.__("¿Quieres guardar los cambios?"), L10n.__("Sí"), L10n.__("No"), (confirmed) => {
			if (!confirmed) {
				notifySaved(false);
				return;
			}
            APIAdminRequest("save-vendedores-order", { vendedores: vendedores }).then(() => {
                APIAdminRequest("save-vendedor-types", { types: vendedorTypes.map(type => { type.id = Math.max(0, type.id); return type; }), locale: L10n.GetLanguage() }).then((response) => {
                    notifySaved(true);
                });
            });
		});
	}, [vendedorTypes, vendedores]);

	return (
		<div className="admin-page admin-negocios">
			{permissionsContext.user.is_superuser == 1 && <>
			<AdminBar onSave={save} />
			<div className="header">
				<a
					href="#"
					className="btn"
					onClick={(e) => {
						e.preventDefault();
						let newTypes = [...vendedorTypes];
						if (newTypes.find((v) => v.order == 0)) {
							for (let i = 0; i < newTypes.length; i++) {
								newTypes[i].order++;
							}
						}
						newTypes.push({
							id: -Math.random(),
							type: "",
							plural: "",
							order: newTypes.length == 0 ? 0 : newTypes[newTypes.length - 1].order + 1,
							main: 0,
							description: ""
						});
						setVendedorTypes(newTypes);

                        setTimeout(() => {
                            $("html, body").animate({ scrollTop: $(".venue-type.fila:last-child").offset().top - $("header.desktop").outerHeight() + $(".header").outerHeight() }, 500);
                        }, 150);
					}}>
					{L10n.__("Nueva categoría")}
				</a>
			</div></>}
			<Bloque className="filas">
				{vendedorTypes.map((type, type_idx) => {
					return (
						<div className={"fila venue-type" + (type.enabled ? "" : " disabled")} key={"type"+type_idx+"-"+type.id}>
							{permissionsContext.user.is_superuser == 1 && <div className="row-actions">
								<div>
									<a
										className="highlight-type"
										href="#"
										onClick={(e) => {
											e.preventDefault();
											if (type.main == 0) type.main = 1;
											else type.main = 0;
											changeVendedorType(type_idx, type);
										}}>
									</a>
									<div className="icon-tooltip">{L10n.__("Destacado")}</div>
								</div>
								<div>
									<a
										href="#"
										className="move-type-up"
										onClick={(e) => {
											e.preventDefault();
											const previousOrder = type.order;
											type.order = Math.max(0, type.order - 1);
											for (let i = 0; i < vendedorTypes.length; i++) {
												if (i == type_idx) continue;
												if (vendedorTypes[i].order == type.order) vendedorTypes[i].order = previousOrder;
											}
											changeVendedorType(type_idx, type);
										}}>
										<MaterialIcon name="arrow_circle_up" />
									</a>
									<div className="icon-tooltip">{L10n.__("Mover hacia arriba")}</div>
								</div>
								<div>
									<a
										href="#"
										className="move-type-down"
										onClick={(e) => {
											e.preventDefault();
											const previousOrder = type.order;
											type.order++;
											for (let i = 0; i < vendedorTypes.length; i++) {
												if (i == type_idx) continue;
												if (vendedorTypes[i].order == type.order) vendedorTypes[i].order = previousOrder;
											}
											changeVendedorType(type_idx, type);
										}}>
										<MaterialIcon name="arrow_circle_down" />
									</a>
									<div className="icon-tooltip">{L10n.__("Mover hacia abajo")}</div>
                                </div>
								<Field
									type="string"
									placeholder={L10n.__("Nombre de la categoría")}
									defaultValue={type.plural}
									onChange={(value) => {
										type.plural = value;
										type.type = value;
										changeVendedorType(type_idx, type);
									}}
								/>
								<div>
									<MaterialIcon name="abc" onClick={e => {
										const n = [...sortBy];
										if (n[type_idx]?.by == "name") {
											n[type_idx].order = n[type_idx].order == "asc" ? "desc" : "asc";
										} else {
											n[type_idx] = { by: "name", order: "asc" };
										}
										setSortBy(n);
									}} className={"sort-by-name sort-button" + (sortBy[type_idx]?.by == "name" ? " active" : "")} />
									<div className="icon-tooltip">{L10n.__("Ordenar alfabéticamente")}</div>
								</div>
                                <div className="toggle-container">
                                    <FormToggle defaultValue={type.enabled == 1} onChange={value => {
                                        const newTypes = [...vendedorTypes];
                                        newTypes[type_idx].enabled = value ? 1 : 0;
                                        setVendedorTypes(newTypes);
                                    }} />
                                    <div className="icon-tooltip">{L10n.__((type.enabled == 1 ? "Desactivar" : "Activar")  + " categoría")}</div>
                                </div>
								<div className="clone-container">
									<a
										className="clone"
										href="#"
										onClick={(e) => {
											e.preventDefault();
											APIAdminRequest("clone-vendedor-type", { id: type.id }).then(response => {
												if (response.status) {
													APIAdminRequest("get-vendedor-types", { class: props.negocioClass || 0 }).then((response) => {
														setVendedorTypes(response.data);
													});
												} else {
													alert(L10n.__(response.message));
												}
											})
										}}>
										<MaterialIcon name="copy_all" />
									</a>
									<div className="icon-tooltip">{L10n.__("Clonar categoría")}</div>
								</div>
								<div className="delete-container">
									<a
										className="delete"
										href="#"
										onClick={(e) => {
											e.preventDefault();
											const newTypes = [...vendedorTypes];
											newTypes.splice(type_idx, 1);
											setVendedorTypes(newTypes);
										}}>
										<MaterialIcon name="delete" />
									</a>
									<div className="icon-tooltip">{L10n.__("Borrar categoría")}</div>
								</div>
							 </div>}
							{permissionsContext.user.is_superuser != 1 &&
							 <div className="row-actions">
								 <h3>{Helper.UCFirst(type.plural)}</h3>
							</div>}
							<div className={"evento-grid" + ((dragStarted && draggedVendedor) ? " dragging" : "")}>
								{vendedores?.filter((v) => v.vendedor_type_id == type.id).sort((a, b) => a.order - b.order).map((vendedor, idx) => {
										let averagePrice = 0;
										let count = 0;

										for (let vivienda of vendedor.viviendas) {
											if (vivienda.price != -1) {
												averagePrice += vivienda.price;
												count++;
											}
										}

										if (count > 0) {
											averagePrice = (averagePrice / count) / 100;
										} else {
											averagePrice = -1;
										}

										return (
											<div
                                                className={"carrusel-main-evento" + (dragStarted && draggedVendedor && draggedVendedor == vendedor ? " dragged-negocio" : "") + (vendedor.enabled ? "" : " disabled")}
                                                key={"mainevento"+vendedor.id+"-"+idx}
                                                onMouseMove={e => {
                                                    if (dragStarted && !draggedVendedor) {
                                                        setDraggedVendedor(vendedor);
                                                    }
                                                }}
                                                onMouseDown={e => {
                                                    setDragStarted(true);
                                                }}
                                                onMouseUp={e => {
                                                    if (draggedVendedor && vendedor != draggedVendedor) {
                                                        const n = [...vendedores];
														n.find(e => e.id == draggedVendedor.id).order = vendedor.order;
														n.find(e => e.id == vendedor.id).order = vendedor.order + 1;
														n.sort((a, b) => a.order - b.order);
														for (let i=n.findIndex(e => e.id == draggedVendedor.id)+1; i<n.length; ++i)
														{
															if (i < 0 || i >= n.length) continue;
															n[i].order = i;
														}
                                                        setVendedores(n);

														const s = [...sortBy];
														s[type_idx] = { by: "", order: "" };
														setSortBy(s);
														setDragStarted(false);
														setDraggedVendedor(null);
                                                    }

                                                    if ((!dragStarted || !draggedVendedor) && !$(e.target).closest('.dot-menu').length) {
                                                        navigate("/admin/venta-vivienda/" + vendedor.id);
                                                    }
                                                }}
                                            >
												<DotMenu align={"left"} options={[
													{ caption: L10n.__("Clonar vendedor"), action: () => {
														APIAdminRequest("clone-vendedor", { id: vendedor.id }).then(response => {
															if (response.status) {
																navigate("/admin/venta-vivienda/" + response.data);
															} else {
																console.error(response.error);
															}
														})
													} },
													{ caption: L10n.__("Eliminar"), action: () => {
														dialogContext.openDialog(L10n.__("¿Seguro que quieres borrar este vendedor y todas sus viviendas?"), L10n.__("Sí"), L10n.__("No"), (confirmed) => {
															if (confirmed) {
																APIAdminRequest("delete-vendedor", { id: vendedor.id }).then(() => {
																	const n = [...vendedores];
																	n.splice(idx, 1);
																	setVendedores(n);
																});
															}
														});
													} }
												]} />
												{!vendedor.enabled && <div className="disabled-tag">{L10n.__("Oculto")}</div>}
												<div className="image-container" style={{ backgroundImage: "url(" + vendedor.image_url + ")" }}>
													{vendedor.iconos && (
														<div className="icons">
															{vendedor.iconos.map((icono, idx) => {
																return (
																	<div
																		style={{ position: "relative" }}
																		onClick={(e) => {
																			e.preventDefault();
																			e.stopPropagation();
																			return false;
																		}}
																		key={"icono"+vendedor.id+"-"+idx}>
																		<img src={icono.image_url} className="icon-image" />
																		<div className="icon-tooltip">{icono.description}</div>
																	</div>
																);
															})}
														</div>
													)}
												</div>
												<div className="details">
													<div className="name">{vendedor.name}</div>
													<div className="city">{vendedor.city}</div>
													<div style={{display:"flex"}}>
														{vendedor.viviendas.length > 0 && averagePrice != -1 && <div className="average-price">
															<div>
																{L10n.__("Precio medio")} <span>{Helper.FormatAmount(averagePrice)}</span>
															</div>
														</div>}
														{vendedor.viviendas.filter(ev => ev.in_pack).length > 0 && <div className="pack-indicator">{L10n.__("Pack")}</div>}
													</div>
													{!!vendedor.mean_rating && <div className="rating-container">
														<StarMeter defaultValue={vendedor.mean_rating} />
														<div className="numeric-value">{parseFloat(vendedor.mean_rating?.toFixed(1)).toLocaleString(L10n.GetLocale())}</div>
													</div>}
												</div>
											</div>
										);
									})}
								<div
                                    className="carrusel-main-evento add-new"
                                    key={type_idx}
                                    onMouseUp={e => {
                                        if (draggedVendedor) {
                                            const n = [...vendedores];
                                            n.splice(n.findIndex(e => e.id == draggedVendedor.id), 1);
                                            n.push(draggedVendedor);
                                            setVendedores(n);
											const s = [...sortBy];
											s[type_idx] = { by: "", order: "" };
											setSortBy(s);
											setDragStarted(false);
											setDraggedVendedor(null);
                                        }

                                        if (!dragStarted || !draggedVendedor) {
                                            navigate("/admin/venta-vivienda/0");
                                        }
                                    }}
                                >
									<MaterialIcon name="add_circle" />
								</div>
							</div>
						</div>
					);
				})}
			</Bloque>
		</div>
	);
}
