import Helper from "./Helper";
import CarouselEventos from "../components/CarouselEventos";
import L10n from "./Locale";
import MaterialIcon from "../components/MaterialIcon";
import { useContext } from "react";
import React from "react";

export interface ISearchResult {
    slug: string;
    average_price: number;
    city: string;
    events: any;
    tipo: any;
    order: any;
    show_ratings: any;
    average_rating: any;
    iconos_detalle: string | any;
    iconos: any;
    id: any;
    image_url: any;
    ofertas_trabajo: any[] | undefined,
    viviendas: any[] | undefined
}

export default class SearchResultsProvider {
    _results: ISearchResult[] = [];
    _elements: ISearchResult[] = [];
    _searchParameters = { ciudad: "", fecha: "", pax: "", priceIndex: -1 };
    _currentMainType: string | null = "";
    _currentOtherType: string | null = "";
    _mainTypeOffset = 0;
    _adminMode = false;
    _adminRefreshCallback: (() => void) | null = null;
    _lastTitleRendered = "";
    _onChangeTypeInfo: ((type: any) => any) | null = null;
    _showAveragePrice = true;
    elementsPerRow = 0;

    constructor(elementList: ISearchResult[], elementsPerRow = 5) {
        this.elementsPerRow = elementsPerRow;
        this.setElementList(elementList);
    }

    setShowAveragePrice(show: boolean) {
        this._showAveragePrice = show;
    }

    setOnChangeTypeInfo(cb: (type: any) => any) {
        this._onChangeTypeInfo = cb;
    }

    setAdminRefreshCallback(callback: () => void) {
        this._adminRefreshCallback = callback;
    }

    setAdminMode(adminMode: boolean) {
        this._adminMode = adminMode;
    }

    setElementList(elementList: ISearchResult[]) {
        this._elements = [...elementList];
        this.search(this._searchParameters.ciudad, this._searchParameters.fecha, parseInt(this._searchParameters.pax));
    }

    resetTitleRendering() {
        this._lastTitleRendered = "";
        return true;
    }

    typeMixedId(element: any) {
        if (!element) return "";
        return typeof element.class === "number" ? "venue" + element.id : element.class + "" + element.id;
    }

    search(ciudad = "", fecha: null | Date = null, pax = 1, priceIndex = -1, kind = "") {
        this._results = this.getFilteredElements(ciudad, fecha, pax, priceIndex, kind);
        this._searchParameters = { ciudad, fecha: fecha || "", pax: pax.toString(), priceIndex, kind };
        this.resetRenderingOffsets();

        return this._results;
    }

    getFilteredElements(ciudad: string, fecha: null | Date, pax: number, priceIndex: number, kind: string) {
        let results = [...this._elements];
        
        if (priceIndex != -1) {
            let maxPrice = 0;
            let minPrice = Infinity;

            results.forEach((element) => {
                maxPrice = Math.max(element.average_price, maxPrice);
                minPrice = Math.min(element.average_price, minPrice);
            });

            if (priceIndex == 0) {
                results = results.filter((element) => element.average_price - minPrice <= (maxPrice - minPrice) * 0.33);
            } else if (priceIndex == 1) {
                results = results.filter((element) => element.average_price - minPrice > (maxPrice - minPrice) * 0.33 && element.average_price <= (maxPrice - minPrice) * 0.66);
            } else if (priceIndex == 2) {
                results = results.filter((element) => element.average_price - minPrice > (maxPrice - minPrice) * 0.66);
            }
        }

        if (ciudad != "") {
            results = results.filter((negocio) => negocio.city == ciudad);
        }

        if (fecha) {
            results = results.filter((negocio) => negocio.events.reduce((acc, v) => acc.concat(v.availability), []).filter((row) => row.slot.indexOf(Helper.GetISODate(fecha)) != -1).length > 0);
        }

        if (kind != "") {
            if (kind == "empleo") {
                results = results.filter(negocio => negocio.ofertas_trabajo?.length > 0);
            } else if (kind == "vivienda") {
                results = results.filter(negocio => negocio.viviendas?.length > 0);
            }
        }

        const effectivePax = Math.max(1, pax);

        results = results.filter((negocio) => {
            return negocio.events?.filter(event => event.loadAlways || event.no_calendar).length > 0 || negocio.events.reduce((acc, v) => acc.concat(v.availability), []).filter((row) => {
                return row?.pax >= effectivePax;
            }).length > 0;
        });
        results = results.filter(negocio => negocio.events?.filter(event => event.enabled).length > 0);

        return results.sort((a, b) => {
            if (a.tipo.order < b.tipo.order && b.tipo.main == a.tipo.main) return -1;
            if (a.tipo.order > b.tipo.order && b.tipo.main == a.tipo.main) return 1;
            if (a.tipo.main && !b.tipo.main) return -1;
            if (!a.tipo.main && b.tipo.main) return 1;
            if (a.order < b.order) return -1;
            if (a.order > b.order) return 1;
            return 0;
        });
    }

    resetRenderingOffsets() {
        if (this._results.length == 0) {
            return;
        }

        this._mainTypeOffset = 0;
        this._currentMainType = this.typeMixedId(this._results.filter((result) => parseInt(result.tipo.main) == 1)[0]?.tipo);
        this._currentOtherType = this.typeMixedId(this._results.filter((result) => parseInt(result.tipo.main) != 1)[0]?.tipo);
    }

    _getMainResultsForRendering(maxCount: number, consumeResults = false) {
        if (!this._currentMainType) {
            return [];
        }

        const currentTypeResults = this._results.filter((result) => parseInt(result.tipo.main) == 1 && this.typeMixedId(result.tipo) == this._currentMainType);
        const results = currentTypeResults.slice(this._mainTypeOffset, this._mainTypeOffset + maxCount).filter(negocio => negocio.events?.filter(event => event.enabled).length > 0);

        if (consumeResults) {
            this._mainTypeOffset += maxCount;

            if (this._mainTypeOffset >= currentTypeResults.length) {
                const mainTypesList = this._results.map((result) => (parseInt(result.tipo.main) == 1 ? this.typeMixedId(result.tipo) : null)).filter((v, i, a) => v && a.indexOf(v) == i);
                const currentIndex = mainTypesList.indexOf(this._currentMainType);

                if (currentIndex >= 0 && currentIndex < mainTypesList.length) {
                    this._currentMainType = mainTypesList[currentIndex + 1];
                    this._mainTypeOffset = 0;
                } else {
                    this._currentMainType = null;
                }
            }
        }

        return results;
    }

    moveType(type, offset: number) {
        if (this._onChangeTypeInfo) {
            const newType = { ...type };
            newType.previousOrder = newType.order;
            newType.order += offset;
            this._onChangeTypeInfo(newType);
        }
    }

    setTypeMain(type, main) {
        if (this._onChangeTypeInfo) {
            const newType = { ...type };
            newType.main = main;
            this._onChangeTypeInfo(newType);
        }
    }

    renderMainRow(maxCount = 10000) {
        const results = this._getMainResultsForRendering(maxCount, true);
        
        if (results.length == 0) {
            return null;
        }

        const currentTipo = results[0].tipo;
        const lastTitleRendered = this._lastTitleRendered;
        this._lastTitleRendered = currentTipo.plural;

        return (
            <div className="container search-results-container">
                <div className="row">
                    <div className="col">
                        {lastTitleRendered != currentTipo.plural && <h2 className="titulo-bodegas">{Helper.UCFirst(currentTipo.plural)}</h2>}
                        <div className="bodegas-container">
                            <div className="carrusel-main-eventos" style={{ gridTemplateColumns: "repeat(" + this.elementsPerRow + ", " + this.elementsPerRow + "fr)", width: Helper.IsResponsive() ? "calc(" + results.length * 100 + "vw - " + ((results.length > 1 ? 30 : 0) + results.length * 25) + "px)" : "auto" }}>
                                {results.map((negocio, idx) => {
                                    let link;

                                    if (!!negocio.ofertas_trabajo) link = "/empleo/" + negocio.slug;
                                    else if (!!negocio.viviendas) link = "/vivienda/" + negocio.slug;
                                    else link = "/venue/" + negocio.slug;

                                    const skeleton = negocio.events?.filter(e => e.loadAlways).length > 0;

                                    let averageRating = 0;
                                    let count = 0;

                                    if (negocio.show_ratings) {
                                        negocio.average_rating?.forEach(rating => {
                                            if (rating.value > 0) {
                                                averageRating += rating.value;
                                                count++;
                                            }
                                        });

                                        if (count > 0) {
                                            averageRating /= count;
                                        }
                                    }

                                    const iconos_detalle = typeof negocio.iconos_detalle == "object" ? negocio.iconos_detalle : JSON.parse(negocio.iconos_detalle || "[]");
                                    const iconos = typeof negocio.iconos == "object" ? negocio.iconos : JSON.parse(negocio.iconos || "[]");

                                    let overPax = false;

                                    return (
                                        <div className={"carrusel-main-evento cover-zoom-listener" + (skeleton ? " skeleton" : "")} key={negocio.image_url + "-" + idx} onClick={_ => {
                                            if (skeleton) return;
                                            location.href = link;
                                        }}>
                                            <div className="image-container cover-zoom" style={{ height: 220 + (4 - this.elementsPerRow) * 15, backgroundImage: skeleton ? null : "url(" + negocio.image_url + ")" }}>
                                                {iconos && (
                                                    <div className="icons">
                                                    <>
                                                        {iconos.map((icono, idx) => {
                                                            return (
                                                                <div
                                                                    style={{ position: "relative" }}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        return false;
                                                                    }}
                                                                    key={idx}
                                                                    className="has-tooltip"
                                                                    data-tooltip={icono.description}>
                                                                    <img src={icono.image_url} className="icon-image" />
                                                                </div>
                                                            );
                                                        })}
                                                        {overPax && <div
                                                            style={{ position: "relative" }}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                return false;
                                                            }}
                                                            key={idx}
                                                            className="has-tooltip">
                                                                <MaterialIcon name="person_alert" className="icon-image" tooltip={L10n.__("El número de personas supera tu suscripción.")} />
                                                        </div>}
                                                    </>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="details">
                                                <div className="name">{negocio.name}</div>
                                                <div className="city">{negocio.city}</div>
                                                <div className="mid">
                                                    {iconos_detalle?.map((icono, iconoIdx) => {
                                                        return <div
                                                            style={{ position: "relative" }}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                return false;
                                                            }}
                                                            key={"icono-negocio-" + iconoIdx}
                                                            className="has-tooltip"
                                                            data-tooltip={icono.titulo}
                                                        >
                                                            <img src={icono.image_url} className="icon-image" />
                                                        </div>;
                                                    })}
                                                </div>
                                                <div className="lower">
                                                    {!skeleton && this._showAveragePrice && negocio.average_price != -1 && (
                                                        <div className="minimum-price">
                                                            {!negocio.ofertas_trabajo && !negocio.viviendas && <div>
                                                                {L10n.__("Precio medio")} <span dangerouslySetInnerHTML={{ __html: Helper.FormatAmount(Math.floor(negocio.average_price / 100), false, true, true) }} />
                                                            </div>}
                                                            {!!negocio.ofertas_trabajo && <div>
                                                                {negocio.ofertas_trabajo[0].nombre_tipo_oferta}
                                                            </div>}
                                                            {!!negocio.viviendas && <div>
                                                                {"Precio venta " + Helper.FormatAmount(negocio.viviendas[0].price / 100)}
                                                            </div>}
                                                        </div>
                                                    )}
                                                    {!skeleton && Math.floor(averageRating * 10) > 0 && <div className="average-rating">
                                                        <MaterialIcon name="star_rate" style={{ fontVariationSettings: '"FILL" 1' }} /> {(Math.floor(averageRating * 10) / 10).toLocaleString(L10n.GetLocale())}
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    _getOtherResultsForRendering(consumeResults = false) {
        if (!this._currentOtherType) {
            return [];
        }

        const results = this._results.filter((result) => parseInt(result.tipo.main) != 1 && this.typeMixedId(result.tipo) == this._currentOtherType);

        if (consumeResults) {
            const otherTypesList = this._results.map((result) => (parseInt(result.tipo.main) != 1 ? this.typeMixedId(result.tipo) : null)).filter((v, i, a) => v && i == a.indexOf(v));
            const currentIndex = otherTypesList.indexOf(this._currentOtherType);

            if (currentIndex >= 0 && currentIndex < otherTypesList.length) {
                this._currentOtherType = otherTypesList[currentIndex + 1];
            } else {
                this._currentOtherType = null;
            }
        }

        return results;
    }

    renderOtherRow() {
        const results = this._getOtherResultsForRendering(true);

        if (results.length == 0) {
            return null;
        }

        const otherTipo = results[0].tipo;
        const lastTitleRendered = this._lastTitleRendered;
        this._lastTitleRendered = otherTipo.plural;

        return (
            <div className="banda-otros">
                <div className="container search-results-container">
                    <div className="info">
                        {lastTitleRendered != otherTipo.plural && <h2 className="titulo-bodegas">{Helper.UCFirst(otherTipo.plural)}</h2>}
                        <div>{otherTipo.description}</div>
                    </div>
                    <CarouselEventos adminMode={this._adminMode} elements={results} />
                </div>
            </div>
        );
    }

    renderDefaultRows(renderMainRows = true, renderOtherRows = true, limit = -1) {
        if (limit == 0) {
            return null;
        }

        return (
            <>
                {renderMainRows && this.renderMainRow(this.elementsPerRow)}
                {renderOtherRows && this.renderOtherRow()}
                {limit != 0 && ((renderMainRows && this._getMainResultsForRendering(this.elementsPerRow).length) || (renderOtherRows && this._getOtherResultsForRendering().length)) > 0 && this.renderDefaultRows(renderMainRows, renderOtherRows, limit - 1)}
            </>
        );
    }

    getResults() {
        return this._results;
    }
}
