import { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import AdminBar from "./AdminBar";
import { APIAdminRequest } from "../services/API";
import Field from "./Field";
import MaterialIcon from "./MaterialIcon";
import Bloque from "./AdminBloque";
import { DialogContext } from "../context/DialogContext";
import { PermissionsContext } from "../context/PermissionsContext";
import L10n from "../services/Locale";
import FormToggle from "./FormToggle";
import Modal from "./Modal";
import Helper from "../services/Helper";
import { StarMeter } from "./Encuesta";
import DotMenu from "./DotMenu";
import { SubscriptionsContext } from "../context/SubscriptionsContext";

export default function AdminNegocios(props) {
	const [venueTypes, setVenueTypes] = useState([]);
	const [negocios, setNegocios] = useState([]);
	const modalEncuesta = useRef();
	const [modalEncuestaContents, setModalEncuestaContents] = useState([]);
	const [editedEncuestaTypeIndex, setEditedEncuestaTypeIndex] = useState(-1);
	const dialogContext = useContext(DialogContext);
	const permissionsContext = useContext(PermissionsContext);
	const [draggedNegocio, setDraggedNegocio] = useState(null);
	const [dragStarted, setDragStarted] = useState(false);
	const [sortBy, setSortBy] = useState([]);

	const navigate = useNavigate();
	const subscriptionsContext = useContext(SubscriptionsContext);

	function changeVenueType(idx, type) {
		const newTypes = [...venueTypes];
		newTypes[idx] = type;
		newTypes.sort((a, b) => a.order - b.order);
		setVenueTypes(newTypes);
	}

	useEffect(() => {
		sortBy.forEach((x, idx) => {
			if (x?.by) {
				const n = [...negocios];
				negocios?.filter(e => e.venue_type_id == venueTypes[idx].id).sort((a, b) => {
					if (x.order == "asc") {
						if (x.by == "rating") {
							if (a.mean_rating > b.mean_rating) return 1;
							if (a.mean_rating < b.mean_rating) return -1;
							return 0;
						}

						if (x.by == "name") {
							return a.name.localeCompare(b.name);
						}
					} else {
						if (x.by == "rating") {
							if (a.mean_rating > b.mean_rating) return -1;
							if (a.mean_rating < b.mean_rating) return 1;
							return 0;
						}
						if (x.by == "name") {
							return b.name.localeCompare(a.name);
						}
					}
				}).forEach((e, idx) => {
					n[n.findIndex(e2 => e2.id == e.id)].order = idx;
				});
				setNegocios(n);
			}
		});
	}, [sortBy]);

	function getNegocios() {
		APIAdminRequest("get-negocios", { partner_id: permissionsContext.user.id, class: props.negocioClass || 0 }).then(response => {
			for (let i = 0; i < response.data?.length; ++i) {
				if (response.data[i].iconos) response.data[i].iconos = JSON.parse(response.data[i].iconos) || [];;
				if (response.data[i].iconos_detalle) response.data[i].iconos_detalle = JSON.parse(response.data[i].iconos_detalle) || [];
				if (response.data[i].info) response.data[i].info = JSON.parse(response.data[i].info) || {};
				if (response.data[i].encuesta) response.data[i].encuesta = JSON.parse(response.data[i].encuesta) || [];
			}
			response.data.forEach((_, idx) => {
				response.data[idx].order = idx;
			});
			setNegocios(response.data);
		});
	}

	useEffect(() => {
		getNegocios();
	}, [venueTypes]);

	useEffect(() => {
		APIAdminRequest("get-types", { class: props.negocioClass || 0 }).then((response) => {
			setVenueTypes(response.data);
		});

		document.body.classList.add("has-header");
		document.body.classList.add("bg-grey");

		function onMouseUp() {
			setTimeout(() => {
				setDragStarted(false);
				setDraggedNegocio(null);
			}, 150);
		}

		document.addEventListener("mouseup", onMouseUp);

		return () => {
			document.body.classList.remove("bg-grey");
			document.body.classList.remove("has-header");
			document.removeEventListener("mouseup", onMouseUp);
		};
	}, []);

	const save = useCallback(notifySaved => {
		dialogContext.openDialog(L10n.__("¿Quieres guardar los cambios?"), L10n.__("Sí"), L10n.__("No"), (confirmed) => {
			if (!confirmed) {
				notifySaved(false);
				return;
			}
			APIAdminRequest("save-negocios-order", { negocios: negocios, class: props.negocioClass || 0 }).then(() => {
				APIAdminRequest("save-types", { types: venueTypes.map(t => { t.id = Math.max(0, t.id); return t; }), class: props.negocioClass || 0, locale: L10n.GetLanguage() }).then((response) => {
					notifySaved(true);
				});
			});
		});
	}, [venueTypes, negocios]);

	return (
		<div className="admin-page admin-negocios">
			{permissionsContext.user.is_superuser == 1 && <>
				<AdminBar onSave={save} />
				<div className="header">
					<a
						href="#"
						className="btn"
						onClick={(e) => {
							e.preventDefault();
							let newTypes = [...venueTypes];
							if (newTypes.find((v) => v.order == 0)) {
								for (let i = 0; i < newTypes.length; i++) {
									newTypes[i].order++;
								}
							}
							newTypes.push({
								id: -Math.random(),
								type: "",
								plural: "",
								order: newTypes.length == 0 ? 0 : newTypes[newTypes.length - 1].order + 1,
								main: 0,
								description: ""
							});
							setVenueTypes(newTypes);

							setTimeout(() => {
								$("html, body").animate({ scrollTop: $(".venue-type.fila:last-child").offset().top - $("header.desktop").outerHeight() + $(".header").outerHeight() }, 500);
							}, 150);
						}}>
						{L10n.__("Nueva categoría")}
					</a>
				</div></>}
			<Bloque className="filas">
				{venueTypes.map((type, type_idx) => {
					return (
						<div className={"fila venue-type" + (type.enabled ? "" : " disabled")} key={"type" + type_idx + "-" + type.id}>
							{permissionsContext.user.is_superuser == 1 && <div className="row-actions">
								<div>
									<a
										className="highlight-type"
										href="#"
										onClick={(e) => {
											e.preventDefault();
											if (type.main == 0) type.main = 1;
											else type.main = 0;
											changeVenueType(type_idx, type);
										}}>
									</a>
									<div className="icon-tooltip">{L10n.__("Destacado")}</div>
								</div>
								<div>
									<a
										href="#"
										className="move-type-up"
										onClick={(e) => {
											e.preventDefault();
											const previousOrder = type.order;
											type.order = Math.max(0, type.order - 1);
											for (let i = 0; i < venueTypes.length; i++) {
												if (i == type_idx) continue;
												if (venueTypes[i].order == type.order) venueTypes[i].order = previousOrder;
											}
											changeVenueType(type_idx, type);
										}}>
										<MaterialIcon name="arrow_circle_up" />
									</a>
									<div className="icon-tooltip">{L10n.__("Mover hacia arriba")}</div>
								</div>
								<div>
									<a
										href="#"
										className="move-type-down"
										onClick={(e) => {
											e.preventDefault();
											const previousOrder = type.order;
											type.order++;
											for (let i = 0; i < venueTypes.length; i++) {
												if (i == type_idx) continue;
												if (venueTypes[i].order == type.order) venueTypes[i].order = previousOrder;
											}
											changeVenueType(type_idx, type);
										}}>
										<MaterialIcon name="arrow_circle_down" />
									</a>
									<div className="icon-tooltip">{L10n.__("Mover hacia abajo")}</div>
								</div>
								<Field
									type="string"
									placeholder={L10n.__("Nombre de la categoría")}
									defaultValue={type.plural}
									onChange={(value) => {
										type.plural = value;
										type.type = value;
										changeVenueType(type_idx, type);
									}}
								/>
								<div>
									<MaterialIcon name="add_reaction" onClick={e => {
										const encuesta = type.encuesta || [];
										setModalEncuestaContents((typeof encuesta !== "object" || typeof encuesta.length === "undefined") ? [] : encuesta);
										setEditedEncuestaTypeIndex(type_idx);
										modalEncuesta.current.open();
									}} />
									<div className="icon-tooltip">{L10n.__("Encuesta de satisfacción")}</div>
								</div>
								<div>
									<MaterialIcon name="stars" onClick={e => {
										const n = [...sortBy];
										if (n[type_idx]?.by == "rating") {
											n[type_idx].order = n[type_idx].order == "asc" ? "desc" : "asc";
										} else {
											n[type_idx] = { by: "rating", order: "asc" };
										}
										setSortBy(n);
									}} className={"sort-button" + (sortBy[type_idx]?.by == "rating" ? " active" : "")} />
									<div className="icon-tooltip">{L10n.__("Ordenar por valoración")}</div>
								</div>
								<div>
									<MaterialIcon name="abc" onClick={e => {
										const n = [...sortBy];
										if (n[type_idx]?.by == "name") {
											n[type_idx].order = n[type_idx].order == "asc" ? "desc" : "asc";
										} else {
											n[type_idx] = { by: "name", order: "asc" };
										}
										setSortBy(n);
									}} className={"sort-by-name sort-button" + (sortBy[type_idx]?.by == "name" ? " active" : "")} />
									<div className="icon-tooltip">{L10n.__("Ordenar alfabéticamente")}</div>
								</div>
								<div className="toggle-container">
									<FormToggle defaultValue={type.enabled == 1} onChange={value => {
										const newTypes = [...venueTypes];
										newTypes[type_idx].enabled = value ? 1 : 0;
										setVenueTypes(newTypes);
									}} />
									<div className="icon-tooltip">{L10n.__((type.enabled == 1 ? "Desactivar" : "Activar") + " categoría")}</div>
								</div>
								<div className="clone-container">
									<a
										className="clone"
										href="#"
										onClick={(e) => {
											e.preventDefault();
											APIAdminRequest("clone-venue-type", { id: type.id }).then(response => {
												if (response.status) {
													APIAdminRequest("get-types", { class: props.negocioClass || 0 }).then((response) => {
														setVenueTypes(response.data);
													});
												} else {
													alert(L10n.__(response.message));
												}
											})
										}}>
										<MaterialIcon name="copy_all" />
									</a>
									<div className="icon-tooltip">{L10n.__("Clonar categoría")}</div>
								</div>
								<div className="delete-container">
									<a
										className="delete"
										href="#"
										onClick={(e) => {
											e.preventDefault();
											const newTypes = [...venueTypes];
											newTypes.splice(type_idx, 1);
											setVenueTypes(newTypes);
										}}>
										<MaterialIcon name="delete" />
									</a>
									<div className="icon-tooltip">{L10n.__("Borrar categoría")}</div>
								</div>
							</div>}
							{permissionsContext.user.is_superuser != 1 &&
								<div className="row-actions">
									<h3>{Helper.UCFirst(type.plural)}</h3>
								</div>}
							<div className={"evento-grid" + ((dragStarted && draggedNegocio) ? " dragging" : "")}>
								{negocios?.filter((v) => v.venue_type_id == type.id).sort((a, b) => a.order - b.order).map((negocio, idx) => {
									let averagePrice = 0;
									let count = 0;

									for (let evento of negocio.events) {
										if (subscriptionsContext.subscriptionsEnabled) {
											if (evento.event_subscription_data && evento.event_subscription_data.price != -1) {
												averagePrice += evento.event_subscription_data.price;
												count++;
											}
										} else {
											if (evento.price != -1) {
												averagePrice += evento.price;
												count++;
											}
										}
									}

									if (count > 0) {
										averagePrice = (averagePrice / count) / 100;
									} else {
										averagePrice = -1;
									}

									return (
										<div
											className={"carrusel-main-evento" + (dragStarted && draggedNegocio && draggedNegocio == negocio ? " dragged-negocio" : "") + (negocio.enabled ? "" : " disabled")}
											key={"mainevento" + negocio.id + "-" + idx}
											onMouseMove={e => {
												if (dragStarted && !draggedNegocio) {
													setDraggedNegocio(negocio);
												}
											}}
											onMouseDown={e => {
												setDragStarted(true);
											}}
											onMouseUp={e => {
												if (draggedNegocio && negocio != draggedNegocio) {
													const n = [...negocios];
													n.find(e => e.id == draggedNegocio.id).order = negocio.order;
													n.find(e => e.id == negocio.id).order = negocio.order + 1;
													n.sort((a, b) => a.order - b.order);
													for (let i = n.findIndex(e => e.id == draggedNegocio.id) + 1; i < n.length; ++i) {
														if (i < 0 || i >= n.length) continue;
														n[i].order = i;
													}
													setNegocios(n);

													const s = [...sortBy];
													s[type_idx] = { by: "", order: "" };
													setSortBy(s);
													setDragStarted(false);
													setDraggedNegocio(null);
												}

												if ((!dragStarted || !draggedNegocio) && !$(e.target).closest('.dot-menu').length) {
													navigate("/admin/negocios/" + negocio.id);
												}
											}}
										>
											<DotMenu align={"left"} options={[
												{
													caption: L10n.__("Clonar negocio"), action: () => {
														APIAdminRequest("clone-negocio", { id: negocio.id }).then(response => {
															if (response.status) {
																navigate("/admin/negocios/" + response.data);
															} else {
																console.error(response.error);
															}
														})
													}
												},
												{
													caption: L10n.__("Eliminar"), action: () => {
														dialogContext.openDialog(L10n.__("¿Seguro que quieres borrar este negocio y todos sus eventos?"), L10n.__("Sí"), L10n.__("No"), (confirmed) => {
															if (confirmed) {
																APIAdminRequest("delete-negocio", { id: negocio.id }).then(() => {
																	const n = [...negocios];
																	n.splice(idx, 1);
																	setNegocios(n);
																});
															}
														});
													}
												}
											]} />
											{!negocio.enabled && <div className="disabled-tag">{L10n.__("Oculto")}</div>}
											<div className="image-container" style={{ backgroundImage: "url(" + (negocio.image_url[0] == "/" ? negocio.image_url : "/static/images/eventos/" + negocio.image_url) + ")" }}>
												{negocio.iconos && (
													<div className="icons">
														{negocio.iconos.map((icono, idx) => {
															return (
																<div
																	style={{ position: "relative" }}
																	onClick={(e) => {
																		e.preventDefault();
																		e.stopPropagation();
																		return false;
																	}}
																	key={"icono" + negocio.id + "-" + idx}>
																	<img src={icono.image_url} className="icon-image" />
																	<div className="icon-tooltip">{icono.description}</div>
																</div>
															);
														})}
													</div>
												)}
											</div>
											<div className="details">
												<div className="name">{negocio.name}</div>
												<div className="city">{negocio.city}</div>
												<div style={{ display: "flex" }}>
													{negocio.events.length > 0 && averagePrice != -1 && <div className="average-price">
														<div>
															{L10n.__("Precio medio")} <span>{Helper.FormatAmount(averagePrice)}</span>
														</div>
													</div>}
													{negocio.events.filter(ev => ev.in_pack).length > 0 && <div className="pack-indicator">{L10n.__("Pack")}</div>}
												</div>
												<div className="rating-container">
													<StarMeter defaultValue={negocio.mean_rating} />
													<div className="numeric-value">{parseFloat(negocio.mean_rating.toFixed(1)).toLocaleString(L10n.GetLocale())}</div>
												</div>
											</div>
										</div>
									);
								})}
								<div
									className="carrusel-main-evento add-new"
									key={type_idx}
									onMouseUp={e => {
										if (draggedNegocio) {
											const n = [...negocios];
											n.splice(n.findIndex(e => e.id == draggedNegocio.id), 1);
											n.push(draggedNegocio);
											setNegocios(n);
											const s = [...sortBy];
											s[type_idx] = { by: "", order: "" };
											setSortBy(s);
											setDragStarted(false);
											setDraggedNegocio(null);
										}

										if (!dragStarted || !draggedNegocio) {
											navigate("/admin/negocios/n" + (props.negocioClass || 0));
										}
									}}
								>
									<MaterialIcon name="add_circle" />
								</div>
							</div>
						</div>
					);
				})}
			</Bloque>
			<Modal
				className={"modal-encuesta-satisfaccion" + (!modalEncuestaContents?.length ? " empty" : "")}
				title={L10n.__("Encuesta de satisfacción")}
				renderContent={modal => {
					if (!modalEncuestaContents) return null;

					return <>
						{modalEncuestaContents.length <= 0 && <div>{L10n.__("No hay ninguna categoría definida")}</div>}
						{modalEncuestaContents.length > 0 && <div>
							{modalEncuestaContents.map((line, idx) => {
								return <div className="line" key={"encuesta-line-" + idx + "-" + line.key}>
									<Field type="string" defaultValue={line.text} onChange={value => {
										const n = [...modalEncuestaContents];
										n[idx].text = value;
										setModalEncuestaContents(n);
									}} />
									<MaterialIcon className="delete-button" name="delete" onClick={e => {
										const n = [...modalEncuestaContents];
										n.splice(idx, 1);
										setModalEncuestaContents(n);
									}} />
								</div>;
							})}
						</div>}
						<MaterialIcon className="add-button" name="add_circle" onClick={e => {
							const n = [...modalEncuestaContents];
							n.push({ key: Math.floor(Math.random() * 1000000000), text: L10n.__("Nueva categoría") });
							setModalEncuestaContents(n);
						}} />
					</>;
				}}
				onClose={_cancelled => {
					const n = [...venueTypes];
					n[editedEncuestaTypeIndex].encuesta = modalEncuestaContents;
					setVenueTypes(n);
				}}
				ref={ref => { modalEncuesta.current = ref }}
			/>
		</div>
	);
}
